.carousel-item img {
    object-fit: cover;
    object-position: center;
    height: 50vh;
    overflow: hidden;
}

.text-carousel-caption {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
    font-weight: bold;
}
